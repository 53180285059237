<template>
  <b-card>
    <baseform
      :fields="fields"
      :posturl="dataurl"
      :title="title"
    />
  </b-card>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    baseform,
    BCard,
  },
  data() {
    return {
      dataurl:"/vehicle-variant",
      baseroute:"vehicle-variant",
      title:"Variant Kendaraan",
      fields : [
        { key: 'code', label: 'Kode', type: 'input', rules:'required' },
        { key: 'model', label: 'Model', type: 'input', rules:'required' },
        { key: 'purchase_price', label: 'Purchase Price', type: 'number', rules:'required' },
        { key: 'capacity', label: 'Kapasitas', type: 'number', rules:'required' },
        { key: 'power', label: 'Power', type: 'input', rules:'required' },
        { key: 'transmission', label: 'Transmisi' ,type: 'input', rules:'required' },
        { key: 'bbm_capacity', label: 'Kapasitas BBM', type: 'number', rules:'required' },
        { key: 'axle_amount', label: 'Axle Amount', type: 'number', rules:'required' },
        { key: 'seat_amount', label: 'Seat Amount', type: 'number', rules:'required' },
        { key: 'km_initial_check', label: 'Km Initial Check', type: 'number', rules:'required' },
        { key: 'km_next_check', label: 'Km Next Check', type: 'number', rules:'required' },
        { key: 'fuel_consumption', label: 'Km/Lt', type: 'number', rules:'required' },
        { key: 'year', label: 'Tahun Produksi', type: 'input-year', rules:'required' },
        { key: 'description', label: 'Deskripsi', type: 'input' },
        { key: 'fuel', label: 'BBM', type: 'select2', rules:'required', dataurl:'/fuel', title:'BBM' },
        { key: 'tire_size', label: 'Ukuran Ban', type: 'select2', rules:'required', dataurl:'/tire-size', title:'Ukuran Ban' },
        { key: 'tire_axle', label: 'Poros Ban', type: 'select2', rules:'required', dataurl:'/tire-axle', title:'Poros Ban' },
        { key: 'type', label: 'Tipe', type: 'select2', rules:'required', dataurl:'/vehicle-type', title:'Tipe Kendaraan' },
        { key: 'manufacture', label: 'Pabrikan', type: 'select2', rules:'required', dataurl:'/vehicle-manufacture', title:'Pabrikan' }, 
      ]
    }
  },
}
</script>